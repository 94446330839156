<template>
<v-app>
    <div>
        <v-toolbar
                dense
                color="primary"
                dark
                extended
                extension-height="120"
                flat
        >
            <v-toolbar-title  slot="extension" >                               

                <h4>Atualização de Endereço</h4> 
                <h6>CEP: {{this.cliente_select.EnderecoCep}}</h6> 
                <h6>{{this.cliente_select.EnderecoLogradouro}}, nº{{this.cliente_select.EnderecoLogradouroNumero}}</h6> 

              


                <v-layout row >
                    <v-flex wrap>
                        <v-btn outline small fab color="white" to="/home" class="outine-2">
                              <i class="fa fa-home fa-2x" ></i>
                        </v-btn>
                    </v-flex>
                   

                    
                </v-layout>
            </v-toolbar-title>
        </v-toolbar>


        <v-container>
         <v-container grid-list-md>  
            <v-form ref="form" v-model="valid" lazy-validation> 

            <v-layout wrap>
               <v-flex xs12 md6>
                    <v-text-field  v-model="cep" :loading="showload_e" Required  label="CEP*" :rules="[v => !!v || 'Campo Obrigatório']" box v-on:change="searchCep"  v-mask="'#####-###'"> </v-text-field>
                </v-flex>


                <v-flex xs12>
                    <v-text-field v-model="model.EnderecoLogradouro" label="Logradouro*" readonly   box></v-text-field>
                </v-flex>


                <v-flex xs12 sm6>
                    <v-text-field v-model="model.EnderecoLogradouroNumero" label="Número"   box></v-text-field>
                </v-flex>

                <v-flex xs12 sm6>
                    <v-text-field value="" v-model="model.EnderecoComplemento" label="Complemento"  box></v-text-field>
                </v-flex>

                  <v-flex xs12 sm6>
                    <v-text-field value="" v-model="bairro" label="Bairro" readonly box></v-text-field>
                </v-flex>

                <v-flex xs12 sm6>
                    <v-text-field v-model="cidade" label="Cidade"  readonly  box></v-text-field>
                </v-flex>

                <v-flex xs12 sm6>
                    <v-text-field v-model="uf"  readonly  box></v-text-field>
                </v-flex>

          
            </v-layout>
                 <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text flat
                        @click="finalizar" 
                    >
                        Sair
                    </v-btn>
                    <v-btn
                        color="primary"
                        :loading="btn_load"
                        :disabled="btn_load"
                        text
                        @click="enviarAtualizacao"
                    >
                        Confirmar
                    </v-btn>
            </v-card-actions>
            </v-form>
        </v-container> 
        </v-container>

         <v-dialog v-model="finish" persistent max-width="340">
            <v-card id="print">
                <v-card-title class="headline">Mensagem</v-card-title>
                <v-card-text v-if="this.load_sorteio == false">{{msg_finish}}</v-card-text>

                <v-card-text v-if="this.load_sorteio == true">
                
                <span> 👏👏👏 Parabéns, seus dados foram atualizados com sucesso! 👏👏👏 </span><br/> 
                <span> BOA SORTE </span><br/>
                <span> Você está participando da PROMOÇÃO PAI PREVIDA É TUDO DE BOM </span><br/>

                
                <span style="font-weight:bold;"> O número do seu cupom é: {{this.volante}} </span><br/>
                <span> O sorteio será realizado no dia 02 de Setembro de 2022.</span><br/>
                <span> Em até 24h você receberá um SMS com o número do seu cupom.</span>

                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                 <v-btn color="green darken-1" flat v-if="this.load_sorteio == true" v-print="'#print'" >Imprimir</v-btn>

                <v-btn color="green darken-1" flat @click="finalizar" >OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

   


    </div>




</v-app>
</template>

<script>
import { http } from '../../services/config'
import authHeader from '../../services/auth-header';
import { mapGetters, mapActions } from 'vuex'
import Promotor from '../../services/promotor'


    export default {
       
        computed:{
            ...mapGetters([
                    'lista_contratos' , 'cliente_select',  'contratoselecionado'
                ]),


        },
           mounted() {
            if(this.cliente_select === undefined | this.isEmptyObject(this.cliente_select)){
                this.$router.push('/home')

            }
        },

        data() {
            return {

                   msg_finish: '',
                   showload_e: false,
                   load_sorteio: false,
                   btn_load: false,
                   cep: '',
                   bairro: '',
                   cidade: '',
                   volante: '',
                   participante:{
                            contrato: "",
                            cpf: "",
                            valor: "",
                            acao: "",
                            titular: "",
                            telefone: "",
                   },
                   uf: '',
                    ev:{
                        key:'',
                        token:'',
                        evento: '',
                        valorantigo: '',
                        valornovo: '',
                        contrato: ''
                        },
                   model: { 
                            Id: 0,
                            EnderecoTipo: {
                                            Id: 1
                                        },
                                EnderecoLogradouro: "",
                                EnderecoLogradouroNumero: "",
                                EnderecoComplemento: "",
                                EnderecoCep: '',
                                EnderecoBairro: {
                                    Id: ''
                                },
                                EnderecoCidade: {
                                    Id: ''
                                },
                                EnderecoUF: {
                                    Id: ''
                                },
                                EnderecoPais: {
                                    Id: 55
                                }
                            
                        },


                    rules: [
                        value => !!value || 'Required.',
                    ],


            }
        },
      

        methods:{
       
                async searchCep() {
                this.showload_e = true;                   
                
                const CEPSemFormatacao = this.cep.replace('-', '') 

                    if(CEPSemFormatacao.length == 8) {
                
                     http.get('EnderecoDIO/ObterEnderecoPorCEP/'+CEPSemFormatacao, {
                                        headers: authHeader()
                                    }).then(response =>{

                        //this.enderecocep = response.data 
                        this.model.Id = this.cliente_select.Id;
                        this.model.EnderecoLogradouro = response.data.ResponseData.Logradouro;
                        this.model.EnderecoCep = this.cep;
                        this.bairro = response.data.ResponseData.NomeBairro;
                        this.model.EnderecoBairro.Id = response.data.ResponseData.IdBairro;
                        this.cidade = response.data.ResponseData.NomeCidade;
                        this.model.EnderecoCidade.Id = response.data.ResponseData.IdCidade;
                        this.uf = response.data.ResponseData.NomeUF;
                        this.model.EnderecoUF.Id = response.data.ResponseData.IdUF;
                        this.model.EnderecoPais.Id = response.data.ResponseData.IdPais;

                        })
                        .catch( error => this.showload_e = false).finally()
                        setTimeout(() => this.showload_e = false, 1500);
                    }
                },

            pontosEndereco(){
                    const obj = {};
                    obj.key = sessionStorage.getItem('token');
                    obj.contrato = sessionStorage.getItem('contrato_token')
                    obj.token = "";
                    obj.evento = "ATUALIZAÇÃO DE ENDEREÇO"
                                           obj.valorantigo = "Cep:"+this.cliente_select.EnderecoCep+" EnderecoLogradouro: "+ this.cliente_select.EnderecoLogradouro+" N: "+ this.cliente_select.EnderecoLogradouroNumero+" Complemento: "+ this.cliente_select.EnderecoComplemento+" Bairro: "+ this.cliente_select.EnderecoBairro.Nome+" Cidade: "+ this.cliente_select.EnderecoCidade.Nome+" UF: "+ this.cliente_select.EnderecoUF.Sigla;
                                           obj.valornovo = "Cep:"+this.model.EnderecoCep+" EnderecoLogradouro: "+ this.model.EnderecoLogradouro+" N: "+ this.model.EnderecoLogradouroNumero+" Complemento: "+ this.model.EnderecoComplemento+" Bairro: "+ this.bairro+" Cidade: "+ this.cidade+" UF: "+ this.uf;
                                       

                                                 Promotor.incluirPontoTokenEndereco(obj).then(response => {
                                                    if(response.status = 200){
                                                    this.eventos = response.data;
                                                    }
                                                }).catch(e => {
                                                }).finally(() => {
                                                });
                            

                },
               isEmptyObject(obj) {
                        var name;

                        for (name in obj) {
                            return false;
                        }
                        return true;
                
                 },
               particicparSorteio(){
                   this.load_sorteio = false
                   this.participante.contrato = sessionStorage.getItem('contrato_token')
                   this.participante.cpf = this.cliente_select.InscricaoAtiva;
                   this.participante.titular = this.cliente_select.NomeCompleto;
                   this.participante.acao = 'AE';
                   this.participante.telefone = this.cliente_select.PrefixoCelular + this.cliente_select.Celular;
                
                
                    Promotor.salvaVolante(this.participante).then(response => {
                    this.load_sorteio = true;

                        if(response.status = 200){

                                if(response.data != 'ERRO'){
                                    this.volante = response.data;
                                    this.load_sorteio = true
                                }else{
                                    this.load_sorteio = false

                                }

                            }
                            }).catch(e => {
                            }).finally(() => {
                    });



               },

                enviarAtualizacao() {
                    

                    if (this.$refs.form.validate()) {
                        this.btn_load = true,   
                        this.model.Id = this.cliente_select.Id                      
                        let aux = false; 
                        
                        this.cliente_select.EnderecoTipo =  this.model.EnderecoTipo; 
                        this.cliente_select.EnderecoLogradouro =  this.model.EnderecoLogradouro;   
                        this.cliente_select.EnderecoLogradouroNumero =  this.model.EnderecoLogradouroNumero; 
                        this.cliente_select.EnderecoCep =  this.model.EnderecoCep;        
                        this.cliente_select.EnderecoComplemento =  this.model.EnderecoComplemento;  
                        this.cliente_select.EnderecoBairro =  this.model.EnderecoBairro;  
                        this.cliente_select.EnderecoCidade =  this.model.EnderecoCidade;  
                        this.cliente_select.EnderecoUF =  this.model.EnderecoUF;  
                        this.cliente_select.EnderecoPais =  this.model.EnderecoPais;  

   
                        http.post('ClienteDIO/Atualizar/', this.cliente_select, {
                                headers: authHeader()
                            }).then(response =>{
                                    if(response.data.Status === 0){
                                        aux = true;                  
                                        this.msg_finish = "Parabéns, Seu endereço foi atualizado sucesso!"
                                    }else{
                                        this.msg_finish = "Ops, Informação não pode ser atualizado no momento, verifique as informações ou tente novamente mais tarde."
                                    }
                                    setTimeout(() => (this.btn_load = false, this.finish = true), 1500)

                                        //this.btn_load = false                  
                                })
                                .catch(function (error) {
                                        this.msg_finish = "Ops, Servidor com problemas, por favor tente novamente mais tarde."
                                        this.btn_load = false                  

                                }).finally(() => {
                                    
                                    if(sessionStorage.getItem('token') == 'null'){
                                        aux = false;
                                    }
                                    if(aux){
                                        this.pontosEndereco();
                                    }

                                this.particicparSorteio();
                                    

                            });
                    }
                },

                finalizar(){
                    
                       this.finish = false
                       this.$router.push('/home')

                }
        
         }
       
    }

</script>

<style scoped>
    .outine-2 {
        border: 2px solid white;
    }

    .card--flex-toolbar {
        margin-top: -124px;
    }

    .learn-more-btn {
        text-transform: initial;
        text-decoration: underline;
    }
</style>